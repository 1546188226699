
import SchoolsService from "@/modules/schools/schools-service";

export default {
    create(payload) {
        return new Promise((resolve, reject) => {
            SchoolsService.create(payload).then(response => {
                resolve({
                    message: "Schools criada com sucesso",
                    success: true,
                    data: response.data
                });
            }).catch(error => {
                console.error(error);
                reject({
                    message: "Erro ao criar Schools",
                    success: false,
                    error
                });
            })
        })
    },
    update(payload) {
        return new Promise((resolve, reject) => {
            SchoolsService.update(payload.id,payload).then(response => {
                resolve({
                    message: "Schools atualizada com sucesso",
                    success: true,
                    data: response.data
                });
            }).catch(error => {
                console.error(error);
                reject({
                    message: "Erro ao atualizar Schools",
                    success: false,
                    error
                });
            })
        })
    },
    remove(id) {
        return new Promise((resolve, reject) => {
            SchoolsService.remove(id).then(response => {
                resolve({
                    message: "Schools removida com sucesso",
                    success: true,
                    data: response.data
                });
            }).catch(error => {
                console.error(error);
                reject({
                    message: "Erro ao remover Schools",
                    success: false,
                    error
                });
            })
        })
    },
    delete(id) {
        return new Promise((resolve, reject) => {
            SchoolsService.delete(id).then(response => {
                resolve({
                    message: "Schools deletada com sucesso",
                    success: true,
                    data: response.data
                });
            }).catch(error => {
                console.error(error);
                reject({
                    message: "Erro ao deletar Schools",
                    success: false,
                    error
                });
            })
        })
    },
    get(id) {
        return new Promise((resolve, reject) => {
            SchoolsService.get(id).then(response => {
                resolve({
                    message: "Schools encontrada com sucesso",
                    success: true,
                    data: response.data
                });
            }).catch(error => {
                console.error(error);
                reject({
                    message: "Erro ao encontrar Schools",
                    success: false,
                    error
                });
            })
        })
    },
    paginate(query) {
        return new Promise((resolve, reject) => {
            SchoolsService.paginate(query).then(response => {
                resolve({
                    message: "Schools encontradas com sucesso",
                    success: true,
                    data: response.data
                });
            }).catch(error => {
                console.error(error);
                reject({
                    message: "Erro ao encontrar Schools",
                    success: false,
                    error
                });
            })
        })
    },
    all() {
        return new Promise((resolve, reject) => {
            SchoolsService.all().then(response => {
                resolve({
                    message: "Schools encontradas com sucesso",
                    success: true,
                    data: response.data
                });
            }).catch(error => {
                console.error(error);
                reject({
                    message: "Erro ao encontrar Schools",
                    success: false,
                    error
                });
            })
        })
    }
}
